//@flow
import React from 'react'
import BlogLayout from './BlogLayout';
import { MDXRenderer} from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';

export const query = (data) => {
  return graphql`
      query($slug: String!) {
          mdx(frontmatter: {slug: {eq: $slug}}){
              frontmatter {
                  title
              }
              body
          }
      }
  `
}

const PostLayout = ({data: { mdx: post }}) => {
  return <BlogLayout>
    <h1>{post.frontmatter.title}</h1>
    <MDXRenderer>{post.body}</MDXRenderer>
  </BlogLayout>
}

export default PostLayout
